<template>
  <div v-bind:class="`v-product-${ product.id }`" class="vr-page" v-if="product">
    <div class="vr-stage" id="vr-stage-id">
      <div class="vr-wrap" :style="style">
        <img id="vr-bg-id"  v-if="product.vrbg && product.levels == 'Beginner'" v-bind:src="product.vrbg" />        
        <img id="vr-bg-id"  v-else-if="product.vrbg2 && product.id == '52'" v-bind:src="product.vrbg2" />
        <img id="vr-bg-id"  v-else v-bind:src="product.vrbg1" />
        <img class="find-target" :style="target" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16021703/target.png" />
        <img class="find-ring" v-if="product.levels == 'Beginner'" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16005832/find-ring.png" />
        <img class="find-ring" v-else-if="product.levels !== 'Beginner'" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16222101/gold.png" />
      </div>
    </div>
    <div class="nav d-flex align-items-center">
      <div class="d-flex">
        <router-link :to="'/product/' + product.id">
        <button class="btn text-white"> <font-awesome-icon icon="arrow-left" /></button>
        </router-link>
        <span class="diagonal-break mx-3" ></span> 
      </div>
      <div>
        <h1>{{ product.title }}</h1>
      </div>
    </div>
    <div class="inner">      
      <div class="text-start level-status">{{ product.levels }}</div>
      <div class="product-stage px-2 py-2">
        <div class="found-msg">Treasure Found</div>
        <ul id="scene" >
          <li data-depth="0.6" class="vr-metal"><img v-if="product.vr_metal" v-bind:src="product.vr_metal" /></li>
          <li data-depth="0.2" class="vr-display"><img v-if="product.vr_display" v-bind:src="product.vr_display" /></li>          
        </ul>
      </div>
    </div>
    <div id="infograph-wrap">
      <img id="infograph" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/17013215/tilt-lr.png" />
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'Product',
    props: ['products', 'categories','id'],
    data(){
      return{
        product: null,
        status: '',
        vrx: 0,
        panimgWidth: null,
        findring: false,
        targetel: 0
      }
    },
    computed: {
     style () {
       var vm = this;
        return { transform: 'translateX(' + this.vrx + 'px)', width: vm.panimgWidth+'px'}
      },
      target (){
        var vm = this;
        return { opacity:  vm.targetel}
      }
    },
     methods:{
      loadProd: function(id=null){
        id = (id) ? id : this.id;
        this.product = this.products.find( p => p.id == id );
        window.scrollTo(0, 0);
      },
      ximg(){
        return `transform: translateX(${ this.vrx }px)`
      },
      motion(event=null){
        //orientationlimit
        let vm = this;
        let limit = 180;
        //scale to image width
        let ratio = ( vm.panimgWidth / limit ) 
        let gamma = (event) ? event.gamma : -90;      
        let x = (gamma -90 || -90)
        vm.vrx = (ratio * x) ;
        console.log( `vx:${vm.vrx} ratio:${ratio} x:${x}` )  
        var displayEl = document.getElementsByClassName('found-msg')

        if(x >= -70 && x <= -45  ){
          
          vm.targetel = 0;
        }
        if(x >= -65 && x <= -50  ){
          vm.targetel = .5;
        }
        if(x >= -60 && x <= -55 ){          
          vm.targetel = 1;
          if(!vm.findring){
            //vm.findring = true;
            
            displayEl[0].classList.add('active');
            setTimeout(function(){
              displayEl[0].classList.remove('active');
            },2000)

          }
          
        }
      }

    },
    mounted(){
      this.loadProd();
      var vm = this;

      
      setTimeout(function(){ 
        var info = document.getElementById('infograph-wrap');
        info.remove(); },2500);
      //IMG
      //const img = new Image();
      // img.onload = function() {  
      //   var max = document.getElementsByClassName('vr-stage')[0];
      //   let wh = max.offsetHeight;
      //   let ww = max.offsetWidth;
      //   console.log(this.width,this.height, this.naturalWidth);
      //   console.log(ww,wh)
        
      //   //let ratio = (wh/this.height)
      //   //console.log(ratio * this.width)        
      // }
      // img.src = vm.product.vrbg;
      //var max = document.getElementsByID('vr-stage-id');
      
      
     
        window.addEventListener("deviceorientation", this.motion, true);
     
      

      setTimeout(function(){
        var scene = document.getElementById('scene');
        var px = new window.Parallax(scene);
        if(px) console.log('yes')
        var item = document.getElementById('vr-bg-id');
        vm.panimgWidth = item.width - (window.innerWidth) ;
        vm.motion();
        //vm.panimgWidth = item.width;

        
        // if (px.orientationSupport) {
        //   console.log('ORENtaion');
        //   window.addEventListener('deviceorientation', vm.orient)
        // }
      },1000);

      
      
      
      // if(parallaxInstance) console.log('yes')
      //console.log(window.Parallax);
      
      //console.log(parallaxInstance)
    },
    beforeDestroy() {
        window.removeEventListener("deviceorientation", this.motion);
    },
    watch: {
    '$route.params.id': function (id) {
      this.loadProd(id);
    },    
  },
  }
</script>

<style lang="scss">
  //GOLD
  .v-product-52{
    .vr-metal{
      img{ left: 55%!important; }
    }
    .vr-display{
      img{ bottom: -3%!important; }
    }
  }
  //EQ800
  .v-product-52{
    .vr-display{
      //img{ left: 60%!important; }
    }
  }
  .v-product-50{
    .vr-metal{
      img{ bottom: 10%!important;width: 60%!important; }
    }
    .vr-display{
      img{ bottom: 3%!important; }
    }
  }
  .v-product-48{
    .vr-metal{
      img{ bottom: 10%!important;width: 60%!important; }
    }
    .vr-display{
      img{ bottom: 3%!important; }
    }
  }
  //540 pp
  .v-product-46{
    .vr-metal{
      img{ width: 50%!important;bottom: 10%!important; }
    }
  }
  //540
  .v-product-44{
    .vr-metal{
      img{ width: 50%!important;bottom: 10%!important; }
    }
  }
  //440
  .v-product-42{
    .vr-metal{
      img{ width: 50%!important;bottom: 10%!important; }
    }
  }
  // 330
  .v-product-36{
    .vr-metal{
      img{ width: 50%!important; bottom: 10%!important;}
    }
  }
</style>
<style lang="scss" scoped>
  #infograph-wrap{
    position: absolute;
    background: rgba(255,255,255,0.9);
    left:0; top:0;
    width: 100%; height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
  }
  #infograph{    
    max-width: 50%;
    max-height: 50%;
    object-fit: contain;
    
  }
  .found-msg{
    opacity: 0;
    visibility: hidden;
    background: #da382a;
    padding: 10px;
    width: 80%;
    font-size: 24px;
    bottom: 10%;
    left: 10%;
    position: absolute;
    z-index: 999;
    &.active{
      visibility: visible;
      opacity: 1;
    }
  }
  .vr-page{
    align-self: stretch;
    background-size: cover;
    padding-bottom: 20px;    
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 0;
    #vr-bg-id{
      position: relative;
      left:0;
      //left: -50%;
    }
    .vr-metal,
    .vr-display{
      img{
        width: 50%;
        position: absolute;
        bottom: 0;
        left:50%;
        transform: translateX(-50%)
      }
    }
    .vr-display{
      
    }
    .vr-metal{
      img{
        width: 70%;
        bottom: 20%;
      }
    }
  }
  .product-stage{
    height: 100%;
  }
  .inner{
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
  }
  .nav{
    background-color: #da382a;
    padding: 5px 0;
    position: relative;
    z-index: 1;
  }
  h1{
    margin-bottom: 0;
    padding-left: 5px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 5px;
    font-size: calc(1.075rem + 1.5vw);
    font-family: industry, sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  .vr-stage{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img{
      height: 100%;
    }
  }
  #scene{
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
    padding: 0;
    li{
      width: 100%;
      height: 100%;
    }
    .status{
      position: absolute;
      bottom: 20%;
      color: #000;
    }
  }
  .vr-wrap{
    position: relative;
  }
  .find-ring{
    left: 40%;
    top: 35%;
    width: 40px;
    height: auto!important;
    position: absolute
  }
  .find-target{
    opacity:0;
    left: 41%;
    top: 32%;
    width: 120px;
    height: auto !important;
    position: absolute;
    transform: translateX(-50%);
  }

</style>