<template>
  <div class="product" v-if="product">
    <div class="nav d-flex align-items-center">
      <div class="d-flex">
        <router-link to="/">
        <button class="btn text-white"> <font-awesome-icon icon="arrow-left" /></button>
        </router-link>
        <span class="diagonal-break mx-3" ></span>
      </div>
      <div>
        <h1>{{ product.title }}</h1>
      </div>
    </div>
    <div class="inner">
      <div class="text-start level-status">{{ product.levels }}</div>
      <div class="product-stage px-2 py-2">
        
        <b-carousel 
          v-if="product.images && product.images.length > 1"
          indicators
          controls
          :interval="0"
          >
          <b-carousel-slide class="slide" v-for="item in product.images" :key="item" v-bind:img-src="item"></b-carousel-slide>        
        </b-carousel>
        <img v-else-if="product.images[0]" class="img-fluid"  v-bind:src="product.images[0]" />
        
        <section class="mt-5" v-if="product.callouts">
          <div class="ov-h pt-3" >
            <span v-for="(item,index) in product.callouts" :key="item.id" class="inline-block"><span v-if="index !== 0">/</span><span class="callout-item inline-block">{{ item }}</span></span>
          </div>
          <hr>
        </section>

        <section class="mt-3" v-if="product.icons && product.icons.length > 0">
          <div class="ov-h d-flex align-items-center justify-content-center" >
          <span v-for="(item,index) in product.icons" :key="item.id" class="icon-item inline-block d-flex">
            <img v-if="item == 'nail'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/30193825/icon-nail2.png" v-bind:alt="item" />
            <!-- <img v-else-if="item == 'burger'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16001039/b-icon-burger.png" v-bind:alt="item" /> -->
            <img v-else-if="item == 'largecoin-01'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28203834/CT_Finds_Icon_LargeCoin-01-350x300.png" v-bind:alt="item" />
            <img v-else-if="item == 'ring-01'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28203837/CT_Finds_Icon_Ring-01-350x300.png" v-bind:alt="item" />
            <img v-else-if="item == 'smallcoin-01'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28203839/CT_Finds_Icon_SmallCoin-01-350x300.png" v-bind:alt="item" />
            <img v-else-if="item == 'mediumcoin-01'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28203836/CT_Finds_Icon_MediumCoin.png" v-bind:alt="item" />            
            <!-- <img v-else-if="item == 'tin'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16001037/b-icon-tin.png" v-bind:alt="item" /> -->
            <img v-else-if="item == 'gold'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28205746/Gold_Finds_Icon_Nugget7-01-350x300.png" v-bind:alt="item" />    
            <img v-else-if="item == 'necklace-01'" class="icon" style="margin-right: 8px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/30193825/ico-neck.png" v-bind:alt="item" />    
            <!-- <img v-else-if="item == 'horse'" class="icon" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/16001035/b-icno-horse.png" v-bind:alt="item" />     -->
            <!-- https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/28203836/CT_Finds_Icon_Necklace.png -->
            
            <span class="diagnoal grey" v-if="index !== product.icons.length-1"></span>
          </span>
          </div>
          <hr>
        </section>

        <section class="mt-3" v-if="product.id == '52'">
          <div class="ov-h d-flex align-items-center justify-content-center" >
          <span class="icon-item inline-block d-flex mx-3">
            <img  class="icon" style="max-height: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/28201357/Gold_Finds_Icon_Nugget1_NEW.png" alt="" />            
            <span class="diagnoal grey" ></span>
          </span>
          <span class="icon-item inline-block d-flex mx-3">
            <img  class="icon" style="max-height: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/28201357/Gold_Finds_Icon_Nugget2_NEW.png" alt="" />            
            <span class="diagnoal grey" ></span>
          </span>
          <span class="icon-item inline-block d-flex mx-3">
            <img  class="icon" style="max-height: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/28201357/Gold_Finds_Icon_Nugget3_NEW.png" alt="" />            
            <span class="diagnoal grey" ></span>
          </span>
          <span class="icon-item inline-block d-flex mx-3">
            <img  class="icon" style="max-height: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/28201358/Gold_Finds_Icon_Nugget4_NEW.png" alt="" />            
            <span class="diagnoal grey" ></span>
          </span>
          <span class="icon-item inline-block d-flex mx-3">
            <img  class="icon" style="max-height: 20px;" src="https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/28201358/Gold_Finds_Icon_Nugget5_NEW.png" alt="" />            
            <span class="diagnoal grey" ></span>
          </span>
          
          
          </div>
          <hr>
        </section>

        
        <div class="features" v-if="product.features">
          <div v-html="product.features"></div>
          <hr class="m-1">
        </div>

        <!-- GOLD MONSTER -->
        <section class="mt-3" >
          <video id="video-player" ref="video" class="viode-inline" autoplay loop muted playsinline>
            <source :src="videosrc" />
          </video>
        </section>
       

        <div v-if="product.feature_image">
          <img class="img-fluid" v-bind:src="product.feature_image" alt="Featured Image" />
        </div>

        
      </div>

      

      <div class="signoff mt-3">
          FIND TREASURE ON ANY ADVENTURE
        </div>

      <!-- <div v-if="product.vr_display && product.vr_metal" class="mt-3 vr-support">
        <a @click="goToVR" class="btn btn-spin" >Take it for a swing</a>
      </div> -->
    </div>
    
  </div>
</template>

<script>
  export default {
    name: 'Product',
    props: ['products', 'categories','id'],
    data(){
      return{
        product: null,
        videosrc: null
      }
    },
     methods:{
      loadProd: function(id=null){
        this.videosrc = null;
        id = (id) ? id : this.id;
        this.product = this.products.find( p => p.id == id );
        
        this.changeVideo();
        
        //window.scrollTo(0, 0);
      },
      changeVideo: function(){
        if(this.product.id == '52') this.videosrc = 'https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/15033432/GOLD-MONSTER-1000-Clip-1.mp4';
        if(this.product.id == '50' || this.product.id == '48') this.videosrc = 'https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/15033427/EQUINOX-Clip-1.mp4';
        if(this.product.id == '46' || this.product.id == '44' || this.product.id == '42' || this.product.id == '36') this.videosrc = 'https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/15033429/VANQUISH-Clip-1.mp4';
        if(this.product.id == '12' || this.product.id == '34' ) this.videosrc = 'https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2022/01/15033434/GO-FIND-Clip.mp4';
         
        //Force video load.
        var vid = document.getElementById('video-player')
        
        if(vid) vid.load();
      },
      goToVR: function(){
        var vm = this;
        if (typeof window.DeviceOrientationEvent !== 'function') {
          //return setResult('DeviceOrientationEvent not detected')
          //alert('Device Support Not Detected');
          return vm.$router.push({ path: `/virtual/${vm.product.id}` });
        }
        if (typeof window.DeviceOrientationEvent.requestPermission !== 'function') {
          //return setResult('DeviceOrientationEvent.requestPermission not detected')
          return vm.$router.push({ path: `/virtual/${vm.product.id}` });

        }
        window.DeviceOrientationEvent.requestPermission().then(function() {
          return vm.$router.push({ path: `/virtual/${vm.product.id}` });
        });
      }
    },
    mounted(){
      this.video = null;
      this.loadProd();
    },
    watch: {
    '$route.params.id': function (id) {
      this.loadProd(id);
    },
   
  },
  }
</script>
<style >
  .level-status{
    color: #fff;
    text-transform: uppercase;
    font-family: industry, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 2px;
    margin-left: 70px;
    margin-top: 5px;
  }
  .vr-support{ display: none; }
  .can-vr .vr-support{ display: block!important; }
</style>
<style lang="scss" scoped>
  .viode-inline{
    width:100%;
    max-width: 100%;
  }
  .slide{
    padding-left: 15px;
    padding-right: 15px; 
  }
  .carousel{
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .product{
    align-self: stretch;
    background-image: url(https://minelab-app-prod.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/12/07195801/prod-bg.jpg);
    background-position: center;
    background-size: cover;
    padding-bottom: 20px;
    //min-height: 100%;
    //height: auto;
  }
  .inner{
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav{
    background-color: #da382a;
    padding: 5px 0;
  }
  h1{
    margin-bottom: 0;
    padding-left: 5px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 5px;
    font-size: calc(1.075rem + 1.5vw);
    font-family: industry, sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  .diagnoal{
    // background: rgba(#e2e2e2,0.3);
    // width: 1px;
    // height: 50px;
    // display: inline-block;
    // transform: rotate(25deg);
    // margin: 0 5px;
  }

  .callout-item{
    //background: #a7a9ac;
    color: rgba(255, 255, 255, 0.8);
    padding: 2px;
    margin: 2px 5px;
    text-transform: uppercase;
    font-family: industry, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    display: inline-block;
    //color: #000;
    
    //border-bottom: 1px solid #da382a;
  }
  .icon-item{
    img{
      height: 40px;
      width: auto;
    }
  }
  .ov-h{
    overflow: hidden
  }
  .features{
    text-transform: uppercase;
    font-family: industry, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    /deep/ ul{
      padding:0;
      list-style: none!important;
      text-align: left;
      li{
        // display: flex;
        // justify-content: space-between;
        // text-align: right;
      }
      .hl{
        font-family: industry, sans-serif;
        font-weight: 700;
        text-align: left;
        margin-right: 10px;
      }
    }
  }
  .signoff{
    font-family: industry, sans-serif;
    font-weight: 400;
    font-size: 11px;
  }
  .btn-spin{
    background-color: #da382a;
    color: #fff;
    font-family: industry, sans-serif;
    font-weight: 400;
    border-radius: 0;
    text-transform: uppercase;
    min-width: 200px;;
  }
  /deep/ .carousel-control-prev{ left: -25px; z-index:inherit}
  /deep/ .carousel-control-next{ right: -25px; z-index:inherit}
  /deep/.carousel-indicators{
        z-index: inherit;
        bottom: -60px;
    li{
      width: 16px;
      height: 16px;
      background: black;
      border-radius: 50%;
      margin: 0 4px;
      &.active{
        background-color: #fff;
      }
    }
  }
</style>